@import './custom/asj_reform_constants';    // Bootstrap より先に既定値を設定
@import "~bootstrap/scss/bootstrap";        // Bootstrap 実体
@import 'hover.css/css/hover-min.css';      // ホバーエフェクト
@import '@fancyapps/ui/dist/carousel/carousel.css';
@import '@fancyapps/ui/dist/carousel/carousel.autoplay.css';
@import './vendor/galliard';                // ASJ ローマ字フォント
@import './custom/asj_bootstrap_includes';  // Boostrap 向け mixin など
@import './custom/asj_font';
@import './sessions';

$header-logo-size: 48px;
$header-font-size: 2rem;
$home-main-section-margins: 6rem;

@mixin reform-red {
  color: $red;
}

body.asj-bs {
  &.layout-reform {
    color: $body-text-color;

    h1, h2 {
      color: var(--bs-emphasis-color);
      margin-top: 1em;
      margin-bottom: 1em;
    }

    dl {
      display: flex;
      margin-bottom: 0;

      dt, dd {
        margin-bottom: 0;
      }
      dt {
        color: var(--bs-emphasis-color);
      }
    }

    blockquote {
      position: relative;
      padding: 10px 15px 10px 50px;
      box-sizing: border-box;
      font-size: 1rem;
      font-style: italic;
      color: $body-text-color;
      margin-bottom: 0;

      &:before {
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 15px;
        content: "\f10d";
        font-family: FontAwesome;
        color: $red;
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 900;
      }

      p {
        padding: 0;
        margin: 10px 0;
        line-height: 1.7;
      }
    }

    // Fancyapps Carousel styles
    --f-carousel-dots-height: 0px;
    --f-transition-duration: 1s;
    --f-progress-color: #bbb;

    .f-progress {
      height: 1px;
    }
    .f-carousel__dots,
    .f-carousel__nav {
      display: none;
    }

    .f-carousel {
      visibility: hidden;

      img {
        height: 100%;
        object-fit: cover;
      }
      .f-carousel__nav {
        visibility: hidden;
      }
    }

    // Scroll to top
    #scroll_to_top {
      position: fixed;
      bottom: 20px;
      right: 20px;
      display: none;
    }

    .reform-red {
      @include reform-red;
    }

    .navbar {
      .asj-logo {
        height: $header-logo-size;
      }

      .site-name {
        display: inline-block;
        vertical-align: middle;
        height: $header-logo-size;
        line-height: $header-logo-size;
        font-size: $header-font-size;

        > span {
          @include reform-red;
        }
      }

      .stats {
        font-size: 0.75rem;
      }
    }

    .btn {
      @extend .shadow;
    }

    .required-input {
      font-weight: bold;
      color: $danger;
    }
    .form-label {
      color: $primary;
    }

    .was-validated .form-check-input:invalid,
    .form-check-input.is-invalid {
      border-color: $red;
    }
    .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
      background-color: $red;
    }
    .was-validated .form-check-input:invalid ~ .form-check-label,
    .form-check-input.is-invalid ~ .form-check-label {
      @include reform-red;
    }

    .hint {
      font-size: 0.75rem;
    }

    .main-content {
      .hero {
        img {
          width: 100%;
          max-height: 50vh;
          object-fit: cover;
        }
      }
      .photographers {
        font-size: 9px;
      }

      .campaign {
        max-width: 45rem;
      }

      .examples,
      .try-contact,
      .try-architect {
        margin-top: $home-main-section-margins;
        margin-bottom: $home-main-section-margins;
      }

      .examples {
        .hero {
          img {
            width: 100%;
            object-cover: cover;
          }
        }
        .title {
          span {
            @include reform-red;
          }
        }

        > section[id^="example"] {
          margin-top: 6rem;
          margin-bottom: 6rem;
        }
      }
    }

    &.reform-publics-architects {
      .main-content {
        .nav-pills {
          flex-basis: 6rem;
        }
        .tab-pane {
          position: relative;
          height: 100%;

          .spinner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.7);
            z-index: 100;

            > div {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translateX(-50%) translateY(-50%);
              font-size: 4rem;
            }
          }
        }

        .architects {
          height: 100%;

          .pagination {
            justify-content: center;
            @include media-breakpoint-only(xs) {
              --bs-pagination-padding-x: 0.5rem;
              --bs-pagination-padding-y: 0.25rem;
              --bs-pagination-font-size: 0.875rem;
              --bs-pagination-border-radius: 0.25rem;
            }

            .page-item {
              &.hide-for-xs {
                display: none;
                @include media-breakpoint-up(sm) {
                  display: list-item;
                }
              }
            }
          }

          .card {
            h2 {
              font-size: 1rem;
              @include media-breakpoint-only(xs) {
                font-size: 14px;
              }
            }
            img {
              max-height: 10rem;
              object-fit: cover;
            }

            .card-body {
              @include media-breakpoint-only(xs) {
                padding: 0.75rem;
              }
            }

            .architect-prefecture {
              font-size: 0.75rem;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .region-tabs {
          @include media-breakpoint-only(xs) {
            font-size: 14px;
          }
        }

        .prefecture-tabs-content {
          height: 100%;
        }

        #architect_modal {
          figure {
            img {
              margin-bottom: 0;
            }
          }

          .architect-hero {
            figure {
              margin-bottom: 0;
              img {
                max-height: 10rem;
              }
            }
          }

          .architect-history {
            h3 {
              color: $red;
            }
            dl {
              dt {
                flex-basis: 8rem;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
  }
}

@import './custom/asj_reform_jekyll';
