@charset 'utf-8';
@import "~bootstrap/scss/bootstrap";        // Bootstrap 実体

body.asj-bs {
  &.layout-reform {
    h2.example-title {
      color: #c58c13;
      margin-bottom: -3em;
    }
    .reform-example {
      margin-top: 10em;
      .container {
        margin-right: 0;
        position: relative;
        .row {
          padding: 0;
          background-color: #c58c13;

          .col {
            padding: 4px;
            color: $white;
          }
          .img-after {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            span {
              bottom: 10px;
              right: 10px;
              color: $white;
              font-size: 10px;
            }
          }
          .img-before {
            padding: 0;
            position: absolute;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 4px solid #c58c13;
            }

          }
        }
      }


      .img-before:before, .img-before:after {
        background-size: contain;
        display: block;
        position: absolute;
        background-position: center;
        //box-shadow: $box-shadow-lg;
      }

      .img-before:before {
        content: "";
        background-image: url('images/reform/publics/home/label-ba-b.png');
        background-color: $black;
      }

      .img-before:after {
        content: "";
        background-image: url('images/reform/publics/home/label-ba-a.png');
        background-color: $white;
        border: 2px solid #666;
      }
    }
    .comment {
      dl {
        dt.colon {
          color: $white!important;
        }
        dt.colon:after {
          content:":";
          color: $white;
          margin-right: 5px;
        }
      }
    }


    @include media-breakpoint-down(md) {
      h2.example-title {
        margin-bottom: 1em;
      }
      .reform-example {
        .container {
          width: 100%;
          .row {
            .img-before {
              top: -120px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
            }
            .img-after {
              height: 430px;
            }

            .img-before:before, .img-before:after {
              width: 60%;
              height: 40px;
              left: 50%;
              transform: translateX(-50%);
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              background-size: contain;
            }
            .img-before:before {
              bottom: 0;
            }
            .img-before:after {
              bottom: -40px;
            }
            .parts-label {
              padding: 1.5em 1em;
              img, source {
                width: 45%;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      .reform-example {
        .container {
          width: 90%;
          .row {
            .img-before {
              top: -50px;
              left: -50px;
            }
            .img-after {
              height: 400px;
            }
            .img-before:before, .img-before:after {
              width: 70%;
              height: 50px;
            }
            .img-before:before {
              bottom: 0;
              right: 5%;
            }
            .img-before:after {
              bottom: -50px;
              right: -20%;
            }
            .parts-label {
              img, source {
                width: 90%;
              }
            }
          }
        }
      }
      .comment {
        padding-left: 10%;
      }
    }
    @include media-breakpoint-up(lg) {
      .reform-example {
        .container {
          .row {
            .img-before {
            }
            .img-after {
              height: 450px;
            }
          }
        }
      }
    }
  }
}
