@charset 'utf-8';

// Fonts ///////////////////////////////////////////////////////////////
// For Bootstrap
$font-family-sans-serif: 'poynter-gothic-text', 'ryo-gothic-plusn', '游ゴシック', YuGothic, 'メイリオ', Meiryo, 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


// Colors //////////////////////////////////////////////////////////////
// For Bootstrap
$min-contrast-ratio: 2.5;

$blue: #154489;
$secondary: #75736d;
$red: #E65607;
$green:  #89A346;
$tan: #857061;

$body-text-color: #7a7a7a;
$heading-text-color: black;

$nav-pills-link-active-bg: $secondary;
$nav-link-color: black;
$nav-link-hover-color: $red;

$pagination-color: black;
$pagination-hover-color: $red;

$pagination-active-bg: $secondary;
$pagination-active-color: white;

// For Mobiscroll 5
$mbsc-ios-accent: #154489;
$mbsc-mobiscroll-accent: #154489;


// Spacing /////////////////////////////////////////////////////////////
$enable-negative-margins: true;
$navbar-nav-link-padding-x: 1rem;


// Sizes ///////////////////////////////////////////////////////////////
$header-logo-size: 2rem;
